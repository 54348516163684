import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import axios from "axios";
import moment from "moment";
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { InputLabel } from "@mui/material";

export default function GroupCounselling() {
	const currentDate = dayjs();
	const [openViewStudent, setOpenViewStudent] = useState(false);
	const [aspiration, setAspiration] = useState("");
	const [grades, setGrades] = useState([]);
	const [grade, setGrade] = useState("");
	const [boards, setBoards] = useState([]);
	const [board, setBoard] = useState("");
	const [callApi, setCallApi] = useState(false);
	const [endValue, setEndValue] = React.useState("");
	const [StartValue, setStartValue] = React.useState("");

	const [allCounsellings, setAllCounsellings] = useState([]);
	const [aspirations, setAspirations] = useState([]);
	const getAllCounsellings = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_URL}package/get-group-counselling-students`,
				{
					aspiration,
					grade,
					endValue,
					StartValue,
					board,
					batchId: false,
				},
			)
			.then((res) => {
				setAllCounsellings(res.data.studentDetails);
			})
			.catch((e) => {});
	};

	const fetchBoards = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
			.then((res) => {
				setBoards(res.data.Boards);
			})
			.catch((e) => {
				setBoards([]);
			});
	};
	const fetchProfessions = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
			.then((res) => {
				setAspirations(res.data.Professions);
			})
			.catch((e) => {
				setAspiration([]);
			});
	};
	const fetchGrades = async (board) => {
		await axios
			.get(
				`${process.env.REACT_APP_BASE_URL}grade/get-grades-by-board/${board}`,
			)
			.then((res) => {
				setGrades(res.data.grades);
			})
			.catch((e) => {
				setGrades([]);
			});
	};

	const handleSetStartValue = (newValue) => {
		const isoFormattedTime = newValue.toISOString();
		setStartValue(isoFormattedTime);
	};
	const handleSetEndValue = (newValue) => {
		const isoFormattedTime = newValue.toISOString();
		setEndValue(isoFormattedTime);
	};

	useEffect(() => {
		fetchBoards();
		if (boards.length > 0) {
			fetchGrades(board);
		}
		getAllCounsellings();
		fetchProfessions();
	}, [callApi]);

	const student_data = {
		columns: [
			{
				label: "Batch Name",
				field: "batchName",
				sort: "asc",
				width: 150,
			},
			{
				label: "First Name",
				field: "first_name",
				sort: "asc",
				width: 150,
			},
			{
				label: "Last Name",
				field: "last_name",
				sort: "asc",
				width: 150,
			},
			{
				label: "Phone",
				field: "phone",
				sort: "asc",
				width: 200,
			},
			{
				label: "Board",
				field: "board",
				sort: "asc",
				width: 100,
			},
			{
				label: "School",
				field: "school",
				sort: "asc",
				width: 150,
			},
			{
				label: "Aspirant",
				field: "aspirant",
				sort: "asc",
				width: 100,
			},
			{
				label: "State",
				field: "state",
				sort: "asc",
				width: 100,
			},
			{
				label: "District",
				field: "district",
				sort: "asc",
				width: 100,
			},
			{
				label: "Grade",
				field: "grade",
				sort: "asc",
				width: 100,
			},
			{
				label: "Counselling Type",
				field: "counselling_type",
				sort: "asc",
				width: 100,
			},
			{
				label: "DOJ",
				field: "doj",
				sort: "asc",
				width: 100,
			},
			{
				label: "DOE",
				field: "doe",
				sort: "asc",
				width: 100,
			},
		],
		rows: allCounsellings?.map((counseling) => {
			return {
				batchName: counseling?.student?.batchName,
				first_name: counseling.student.first_name,
				last_name: counseling.student.last_name,
				board: counseling.student.board,
				school: counseling.student.school,
				phone: counseling.student.mobile,
				aspirant: counseling.student.aspirant,
				state: counseling.student.state,
				district: counseling.student.district,
				grade: counseling.student.grade,
				counselling_type: "Group Counselling",
				doj: moment(counseling.student.doj).format("DD-MM-YYYY"),
				doe: moment(counseling.student.doe).format("DD-MM-YYYY"),
			};
		}),
	};

	return (
		<PageLayout>
			<Row>
				<Col xl={12}>
					<Breadcrumb title={"Group Counselling Students List"}></Breadcrumb>
				</Col>
				<Col xl={12}>
					<Box className="mc-card">
						<Row xs={1} md={1} xl={1}>
							<Col xl={3}>
								<CardHeader title="Start Date" />
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={StartValue}
										onChange={handleSetStartValue}
										renderInput={(params) => (
											<TextField {...params} disabled={true} />
										)}
										name="start_time"
										minDateTime={currentDate}
									/>
								</LocalizationProvider>
							</Col>
							<Col xl={3}>
								<CardHeader title="End Date" />
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={endValue}
										onChange={handleSetEndValue}
										renderInput={(params) => (
											<TextField {...params} disabled={true} />
										)}
										name="end_time"
										minDateTime={currentDate}
									/>
								</LocalizationProvider>
							</Col>

							<Col xl={2}>
								<div>
									<CardHeader title="Select Aspiration" />
									<FormControl sx={{ width: "100%" }}>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={aspiration}
											onChange={(e) => setAspiration(e.target.value)}
										>
											<MenuItem value="">None</MenuItem>
											{aspirations.length > 0 &&
												aspirations.map((aspiration) => {
													return (
														<MenuItem value={aspiration.name}>
															{aspiration.name}
														</MenuItem>
													);
												})}
										</Select>
									</FormControl>
								</div>
							</Col>
							<Col xl={2}>
								<CardHeader title="Select Board" />
								<FormControl sx={{ width: 150 }}>
									<InputLabel id="demo-simple-select-label">Board</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="Board"
										value={board}
										onChange={(e) => {
											setBoard(e.target.value);
											setCallApi(!callApi);
										}}
									>
										<MenuItem value={""}>None</MenuItem>
										{boards.length > 0 &&
											boards.map((board) => {
												return (
													<MenuItem value={board._id}>{board.name}</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<CardHeader title="Select Grade" />
								<FormControl sx={{ width: 150 }}>
									<InputLabel id="demo-simple-select-label">Grade</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										label="Grade"
										id="demo-simple-select"
										value={grade}
										onChange={(e) => {
											setGrade(e.target.value);
											setCallApi(!callApi);
										}}
									>
										<MenuItem value={""}>None</MenuItem>
										{grades.length > 0 &&
											grades.map((grade) => {
												return (
													<MenuItem value={grade._id}>{grade.name}</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>

							<Col xl={10}></Col>
							<Col xl={2}>
								<div
									style={{
										display: "flex",
										width: "100%",
										justifyContent: "end",
										alignItems: "center",
									}}
								>
									<Button
										onClick={(e) => {
											e.preventDefault();
											getAllCounsellings();
										}}
										className="btn btn-primary"
									>
										Apply
									</Button>
								</div>
							</Col>

							<Col xl={12}>
								<Box className="mc-card">
									<div className="container" style={{ marginTop: "1rem" }}>
										<div
											className="row"
											style={{ justifyContent: "end", width: "100%" }}
										></div>
										<div className="row">
											<div className="col-12">
												<MDBDataTable
													striped
													bordered
													small
													data={student_data}
													style={{
														marginTop: "1rem",
														display: "block",
														overflowX: "auto",
														whiteSpace: "nowrap",
													}}
												/>
											</div>
										</div>
									</div>
								</Box>
							</Col>
						</Row>
					</Box>
				</Col>

				{/* View Student Modal */}
				<Modal
					aria-labelledby="modal-title"
					aria-describedby="modal-desc"
					open={openViewStudent}
					onClose={() => setOpenViewStudent(false)}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: "1000",
					}}
				>
					<Sheet
						variant="outlined"
						sx={{
							width: "50%",
							borderRadius: "md",
							p: 3,
							boxShadow: "lg",
							bgcolor: "#fff",
						}}
					>
						<ModalClose
							variant="outlined"
							sx={{
								top: "calc(-1/4 * var(--IconButton-size))",
								right: "calc(-1/4 * var(--IconButton-size))",
								boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
								borderRadius: "50%",
								bgcolor: "#eee",
							}}
						/>
						<Typography
							id="basic-modal-dialog-title"
							component="h2"
							level="inherit"
							fontSize="1.25em"
							mb={1}
						>
							Add Feedback
						</Typography>

						<Stack spacing={2} sx={{ alignItems: "center" }}>
							<CardHeader title="Feedback" />
							<TextField
								id="outlined-required"
								defaultValue=""
								placeholder="Enter your feedback ..."
								multiline
								sx={{ width: "100%" }}
							/>
						</Stack>

						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								marginTop: "2rem",
							}}
						>
							<button
								type="button"
								style={{
									width: "8rem",
									fontSize: "0.8rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginRight: "0.5rem",
								}}
								className="btn btn-primary"
							>
								Submit
							</button>
						</div>
					</Sheet>
				</Modal>
			</Row>
		</PageLayout>
	);
}
