import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";

import axios from "axios";
import { InputLabel } from "@mui/material";

export default function BatchList() {
  const [openViewStudent, setOpenViewStudent] = useState(false);

  const [batches, setBatches] = useState([]);

  const [callApi, setCallApi] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [batchNumber, setBatchNumber] = useState(false);

  const [aspirations, setAspirations] = useState([]);

  const [aspiration, setAspiration] = useState("");

  const [selectedBatch, setSelectedBatch] = useState("");

  const [year, setYear] = useState("");
  const [boards, setBoards] = useState([]);
  const [board, setBoard] = useState("");
  const [quarter, setQuarter] = useState("");

  const fetchAllBatches = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}batch/get-all-batches`)
      .then((res) => {
        console.log(res.data);
        setBatches(res.data.batches);
        setNumbers(res.data.numbers);
        // const uniqueAspirations = [...new Set(res.data.map(item => item.aspirant))];
        // setAspirations(uniqueAspirations);
      })
      .catch((e) => {
        setBatches([]);
      });
  };

  const fetchProfessions = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
      .then((res) => {
        setAspirations(res.data.Professions);
      })
      .catch((e) => {
        setAspiration([]);
      });
  };

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const changeStatus = async (batch) => {
    await axios
      .put(`${process.env.REACT_APP_BASE_URL}batch/toggle-active/${batch._id}`)
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const deleteBatch = async (batch) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}batch/delete-batch/${batch._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const batch_data = {
    columns: [
      {
        label: "Batch Name",
        field: "batch_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Aspiration",
        field: "aspirant",
        sort: "asc",
        width: 100,
      },
      {
        label: "YYYY",
        field: "year",
        sort: "asc",
        width: 100,
      },
      {
        label: "QTR",
        field: "quarter",
        sort: "asc",
        width: 100,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 100,
      },
      {
        label: "Session Created",
        field: "sessionCount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Session taken",
        field: "sessionTaken",
        sort: "asc",
        width: 100,
      },
      {
        label: "Add Session",
        field: "add_session",
        sort: "asc",
        width: 100,
      },
      {
        label: "View Session",
        field: "view_session",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: batches?.map((batch) => {
      return {
        batch_name: batch.name,
        aspirant: batch.aspirant,
        year: batch.year,
        quarter: batch.quarter,
        board: batch?.boards[0]?.name,
        sessionCount: batch?.sessionCount,
        sessionTaken: batch?.sessionTaken,
        add_session: (
          <Link to="/add-session" state={{ type: "group", batchId: batch._id }}>
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
              className="btn btn-primary"
            >
              Assign
            </button>
          </Link>
        ),
        view_session: (
          <Link to={`/sessions-list?batchId=${batch._id}&type=group`}>
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
              className="btn btn-primary"
            >
              View
            </button>
          </Link>
        ),
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Link to="/edit-batch" state={{ Batch: batch }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedBatch(batch);
                setWarning({
                  text: `Do you want to change the status to ${
                    batch.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                batch.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {batch.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the batch?`,
                  type: "delete",
                });
                setSelectedBatch(batch);
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  const fetchBoards = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .catch((e) => {
        setBoards([]);
      });
  };

  const fetchBatches = async () => {
    const requestBody = {};

    if (aspiration) {
      requestBody.aspiration = aspiration;
    }

    if (year) {
      requestBody.year = year;
    }

    if (quarter) {
      requestBody.quarter = quarter;
    }

    if (board) {
      requestBody.board = board;
    }
    if (batchNumber) {
      requestBody.batchNumber = batchNumber;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}batch/filter-batches`,
        requestBody
      );
      setBatches(response.data.batches);
      setNumbers(response.data.numbers);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllBatches();
    fetchProfessions();
    fetchBoards();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={"Batches List"}></Breadcrumb>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Aspiration" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={aspiration}
                      onChange={(e) => setAspiration(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {aspirations.length > 0 &&
                        aspirations.map((aspiration) => {
                          return (
                            <MenuItem value={aspiration.name}>
                              {aspiration.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Year" />
                  <FormControl sx={{ width: 150 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value={"2021"}>2021</MenuItem>
                      <MenuItem value={"2022"}>2022</MenuItem>
                      <MenuItem value={"2023"}>2023</MenuItem>
                      <MenuItem value={"2024"}>2024</MenuItem>
                      <MenuItem value={"2025"}>2025</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Quarter" />
                  <FormControl sx={{ width: 260 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={quarter}
                      onChange={(e) => setQuarter(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="Q1">Q1(Jan, Feb, Mar)</MenuItem>
                      <MenuItem value="Q2">Q2(Apr,May, Jun,)</MenuItem>
                      <MenuItem value="Q3">Q3(Jul, Aug,Sep)</MenuItem>
                      <MenuItem value="Q4">Q4(Oct, Nov, Dec)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Batch Number" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={batchNumber}
                      onChange={(e) => setBatchNumber(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {numbers.length > 0 &&
                        numbers.map((number) => {
                          return <MenuItem value={number}>{number}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>
              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Board" />
                  <FormControl sx={{ width: 150 }}>
                    <InputLabel id="demo-simple-select-label">Board</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Board"
                      value={board}
                      onChange={(e) => {
                        setBoard(e.target.value);
                        setCallApi(!callApi);
                      }}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {boards.length > 0 &&
                        boards.map((board) => {
                          return (
                            <MenuItem value={board._id}>{board.name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchBatches();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  <div className="container" style={{ marginTop: "1rem" }}>
                    <div
                      className="row"
                      style={{ justifyContent: "end", width: "100%" }}
                    ></div>
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={batch_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* View Student Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openViewStudent}
          onClose={() => setOpenViewStudent(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Add Feedback
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <CardHeader title="Feedback" />
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter your feedback ..."
                multiline
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedBatch);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteBatch(selectedBatch);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
