import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function AddUpcomingSession() {
	const [showPassword, setShowPassword] = useState(false);

	const handleTogglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const navigate = useNavigate();

	const [showTimeSlots, setShowTimeSlots] = useState(false);

	const [callApi, setCallApi] = useState(false);

	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);

	const [qualifications, setQualifications] = useState([]);

	const [days, setDays] = useState([]);

	const token = localStorage.getItem("token");

	const [openAssignCounsellor, setOpenAssignCounsellor] = useState(false);

	const [selectedCounsellor, setSelectedCounsellor] = useState("");

	const location = useLocation();

	const Error = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const handleChangeDate = (newValue) => {
		if (newValue.isValid()) {
			const isoFormattedDate = newValue.toISOString(); // Convert the time to ISO 8000 format
			setSession({ ...session, date: isoFormattedDate });
		}
	};

	const handleChangeTime = (newValue) => {
		if (newValue.isValid()) {
			const isoFormattedTime = newValue.toISOString(); // Convert the time to ISO 8000 format
			setSession({ ...session, time: isoFormattedTime });
		}
	};

	const [selectedCounsellors, setSelectedCounsellors] = useState([]);

	const [finalSelectedCounsellors, setFinalSelectedCounsellors] = useState([]);

	const [aspirations, setAspirations] = useState(false);
	const [aspiration, setAspiration] = useState(false);

	const [subjects, setSubjects] = useState([]);

	const [counsellors, setCounsellors] = useState([]);

	const [counsellorIds, setCounsellorIds] = useState([]);

	let studentId, batchId, counselling_type;

	counselling_type = location.state.type;

	const getStates = async () => {
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}home/stateList`)
			.then((res) => {
				if (res.data.responseCode) {
					setStateList(res.data.responseData);
				} else {
					Error(res.data.message);
				}
			})
			.catch((e) => {
				Error(e.response.data.message);
			});
	};
	const getCity = async (value) => {
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}home/cityList`, {
				stateId: value,
			})
			.then((res) => {
				if (res.data.responseCode) {
					setCityList(res.data.responseData);
				} else {
					Error(res.data.message);
				}
			})
			.catch((e) => {
				Error(e.response.data.message);
			});
	};
	if (studentId) {
		studentId = location.state.studentId;
	} else if (batchId) {
		batchId = location.state.batchId;
	}

	const [session, setSession] = useState({
		name: "",
		aspirant: location?.state?.aspirant,
		link: "",
		password: "",
		date: "",
		time: "",
		student: "",
		purpose: "",
		counsellor: "",
	});

	const [filters, setFilters] = useState({
		subject: "",
		state: "",
		district: "",
		experience: "",
		qualification: "",
		profession: "",
	});

	const fetchCounsellorsList = async () => {
		let url = `${process.env.REACT_APP_BASE_URL}counsellor/get-counsellors`;

		const queryParameters = Object.entries(filters)
			.filter(([key, value]) => value !== "")
			.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
			.join("&");

		if (queryParameters) {
			url += `?${queryParameters}`;
		}

		await axios
			.get(url, { headers: { jwt: token } })
			.then((res) => {
				setCounsellors(res.data.Counsellors);
			})
			.finally(() => {})
			.catch((e) => {
				localStorage.clear();
				navigate("/login");
			});
	};

	const fetchSubjects = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}subject-area/get-all-subject-area`)
			.then((res) => {
				setSubjects(res.data.SubjectArea);
			})
			.catch((e) => {
				setSubjects([]);
			});
	};

	const handleCounsellorChange = (event, counsellor) => {
		const isChecked = event.target.checked;

		if (isChecked) {
			// Check if the type is "One to One" and clear the selected counsellors if necessary
			if (counselling_type == "one") {
				setSelectedCounsellors([counsellor]);
				setCounsellorIds([counsellor._id]);
			} else {
				// Add the counsellor to the selectedCounsellors array
				setSelectedCounsellors((prevSelectedCounsellors) => [
					...prevSelectedCounsellors,
					counsellor,
				]);
				setCounsellorIds((prevSelectedCounsellors) => [
					...prevSelectedCounsellors,
					counsellor._id,
				]);
			}
		} else {
			// Remove the counsellor from the selectedCounsellors array
			setSelectedCounsellors((prevSelectedCounsellors) =>
				prevSelectedCounsellors.filter(
					(selectedCounsellor) => selectedCounsellor !== counsellor,
				),
			);
			setCounsellorIds((prevSelectedCounsellors) =>
				prevSelectedCounsellors.filter(
					(selectedCounsellor) => selectedCounsellor !== counsellor._id,
				),
			);
		}
	};

	const fetchAllCounsellorsList = async () => {
		let url = `${process.env.REACT_APP_BASE_URL}counsellor/get-counsellors`;

		await axios
			.get(url, { headers: { jwt: token } })
			.then((res) => {
				const uniqueQualifications = [
					...new Set(
						res.data.Counsellors.map((counsellor) => counsellor.qualification),
					),
				];
				setQualifications(uniqueQualifications);
			})
			.finally(() => {})
			.catch((e) => {
				localStorage.clear();
				navigate("/login");
			});
	};

	// const fetchSubjects = async () => {
	// 	await axios
	// 		.get(`${process.env.REACT_APP_BASE_URL}subject/get-all-subjects`)
	// 		.then((res) => {
	// 			res.data.subjects.map((subject) => {
	// 				setSubjects((preValue) => {
	// 					return [...preValue, subject.name.toLowerCase()];
	// 				});
	// 			});
	// 		})
	// 		.catch((e) => {
	// 			setSubjects([]);
	// 		});
	// };

	const currentDate = dayjs();

	const addSession = async () => {
		// if (session.password.length < 8) {
		//   return Error("Password must be of minimum 8 characters !");
		// }
		if (counselling_type == "one") {
			await axios
				.post(`${process.env.REACT_APP_BASE_URL}session/add-session`, {
					type: counselling_type,
					name: session.name,
					aspirant: session.aspirant,
					link: session.link,
					student: location.state.studentId,
					password: session.password,
					date: session.date,
					time: session.time,
					purpose: session.purpose,
					counsellors: counsellorIds,
				})
				.then((res) => {
					navigate(
						`/sessions-list?studentId=${location.state.studentId}&type=one`,
					);
				})
				.catch((e) => {
					console.log(e.response.data.message);
					Error(e.response.data.message);
				});
		} else {
			await axios
				.post(`${process.env.REACT_APP_BASE_URL}session/add-session`, {
					type: counselling_type,
					name: session.name,
					aspirant: session.aspirant,
					link: session.link,
					password: session.password,
					date: session.date,
					batch: location.state.batchId,
					time: session.time,
					purpose: session.purpose,
					counsellors: counsellorIds,
				})
				.then((res) => {
					navigate(
						`/sessions-list?batchId=${location.state.batchId}&type=group`,
					);
				})
				.catch((e) => {
					Error(e.response.data.message);
				});
		}
	};

	const counsellor_data = {
		columns: [
			{
				label: "S.no",
				field: "s_no",
				sort: "asc",
				width: 150,
			},
			{
				label: "Name",
				field: "name",
				sort: "asc",
				width: 150,
			},
			{
				label: "Age",
				field: "age",
				sort: "asc",
				width: 50,
			},
			{
				label: "Email",
				field: "email",
				sort: "asc",
				width: 200,
			},
			{
				label: "Phone",
				field: "phone",
				sort: "asc",
				width: 100,
			},
			{
				label: "State",
				field: "state",
				sort: "asc",
				width: 150,
			},
			{
				label: "District",
				field: "district",
				sort: "asc",
				width: 150,
			},
			{
				label: "Qualification",
				field: "qualification",
				sort: "asc",
				width: 100,
			},
			{
				label: "Subject Area",
				field: "subject_area",
				sort: "asc",
				width: 100,
			},
			{
				label: "Profession",
				field: "profession",
				sort: "asc",
				width: 100,
			},
			{
				label: "Experience",
				field: "experience",
				sort: "asc",
				width: 100,
			},
			{
				label: "Streams",
				field: "streams",
				sort: "asc",
				width: 100,
			},
			{
				label: "Time Slots",
				field: "time",
				sort: "asc",
				width: 100,
			},
		],
		rows: counsellors?.map((counsellor, index) => {
			return {
				s_no: (
					<th scope="row">
						<input
							type="checkbox"
							checked={selectedCounsellors.some(
								(arr) => arr._id === counsellor._id,
							)}
							onChange={(event) => handleCounsellorChange(event, counsellor)}
						/>
						&nbsp;{index + 1}
					</th>
				),
				name: counsellor.name,
				age: counsellor.age,
				email: counsellor.email,
				phone: counsellor.mobile,
				state: counsellor.state,
				district: counsellor.district,
				qualification: counsellor?.qualification?.name,
				subject_area: counsellor?.subjects?.map((subject, index) => {
					return index === counsellor?.subjects?.length - 1 ? (
						<td key={index}>{subject?.name}</td>
					) : (
						<td key={index}>{subject?.name},&nbsp;</td>
					);
				}),
				profession: counsellor?.profession?.name,
				experience: `${counsellor.experience} Years`,
				streams: counsellor?.interested_streams?.map((stream, index) => {
					return index === counsellor?.interested_streams?.length - 1 ? (
						<td key={index}>{stream?.name}</td>
					) : (
						<td key={index}>{stream?.name},&nbsp;</td>
					);
				}),
				time: (
					<td
						style={{ display: "flex", justifyContent: "center", width: "100%" }}
					>
						<button
							onClick={() => {
								setShowTimeSlots(true);
								setSelectedCounsellor(counsellor);
								calculateDays(counsellor.slots);
							}}
							type="button"
							style={{
								fontSize: "0.6rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginRight: "1.6rem",
							}}
							className="btn btn-primary"
						>
							<i className="fas fa-eye"></i>
						</button>
					</td>
				),
			};
		}),
	};

	const calculateDays = (slots) => {
		let SlotDays = [];
		slots.map((slot) => {
			if (!SlotDays.includes(slot.day)) {
				SlotDays.push(slot.day);
			}
		});
		setDays(SlotDays);
	};

	useEffect(() => {
		getStates();
		fetchSubjects();
	}, []);

	useEffect(() => {
		fetchCounsellorsList();
	}, [callApi]);

	useEffect(() => {
		fetchAllCounsellorsList();
	}, []);

	const fetchProfessions = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
			.then((res) => {
				setAspirations(res.data.Professions);
			})
			.catch((e) => {
				setAspiration([]);
			});
	};
	useEffect(() => {
		fetchProfessions();
	}, []);

	return (
		<PageLayout>
			<ToastContainer autoClose={1000} />
			<Row>
				<Col xl={12}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Breadcrumb title={"Add Upcoming Session"}></Breadcrumb>
						{counselling_type === "one" ? (
							<Link
								to={`/sessions-list?studentId=${location.state.studentId}&type=one`}
							>
								<Button className="btn btn-primary">
									View Upcoming Sessions
								</Button>
							</Link>
						) : (
							<Link
								to={`/sessions-list?batchId=${location.state.batchId}&type=group`}
							>
								<Button className="btn btn-primary">
									View Upcoming Sessions
								</Button>
							</Link>
						)}
					</div>
				</Col>
				<Col xl={12}>
					<Box className="mc-card">
						<Row xs={1} md={1} xl={1}>
							<Col xl={2}>
								<CardHeader title="Counselling Type" />
								<TextField
									id="outlined-required"
									value={
										counselling_type === "one"
											? "One to One"
											: counselling_type === "group"
											? "Group Counselling"
											: "Free Career Talk"
									}
									disabled
									sx={{ width: "100%" }}
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Session Name" />
								<TextField
									id="outlined-required"
									defaultValue=""
									value={session.name}
									onChange={(e) =>
										setSession({ ...session, name: e.target.value })
									}
									sx={{ width: "100%" }}
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Aspirant" />
								{/* <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Aspirant
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Aspirant"
                    value="Demo"
                    
                  >
                    <MenuItem value={""}>None</MenuItem>
                    
                  </Select>
                </FormControl> */}
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={session?.aspirant}
										onChange={(e) =>
											setSession({ ...session, aspirant: e.target.value })
										}
									>
										<MenuItem value="">None</MenuItem>
										{aspirations.length > 0 &&
											aspirations.map((aspiration) => {
												return (
													<MenuItem value={aspiration.name}>
														{aspiration.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>

							{/* <Col xl={2}>
                  <CardHeader title="Aspirant" />
                  <TextField
                    id="outlined-required"
                    defaultValue=""
                    value={session.aspirant}
                    onChange={(e) =>
                      setSession({ ...session, aspirant: e.target.value })
                    }
                    sx={{ width: "100%" }}
                  />
                </Col> */}

							<Col xl={2}>
								<CardHeader title="Session Link" />
								<TextField
									id="outlined-required"
									defaultValue=""
									value={session.link}
									onChange={(e) =>
										setSession({ ...session, link: e.target.value })
									}
									sx={{ width: "100%" }}
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Password" />
								<TextField
									id="outlined-required"
									defaultValue=""
									type={showPassword ? "text" : "password"}
									name="password"
									value={session.password}
									onChange={(e) =>
										setSession({ ...session, password: e.target.value })
									}
									sx={{ width: "100%" }}
									placeholder="Enter Password..."
									inputProps={{ minLength: 8 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={handleTogglePasswordVisibility}>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Date" />
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										inputFormat="MM/DD/YYYY"
										value={session.date}
										onChange={handleChangeDate}
										renderInput={(params) => <TextField {...params} />}
										minDate={currentDate}
									/>
								</LocalizationProvider>
							</Col>

							<Col xl={2}>
								<CardHeader title="Time" />
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<TimePicker
										value={session.time}
										onChange={handleChangeTime}
										placeholder="HH:MM"
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</Col>

							<Col
								xl={2}
								style={{
									display: "flex",
									alignItems: "start",
									flexDirection: "column",
								}}
							>
								<CardHeader title="Select Counsellor" />
								<button
									type="button"
									onClick={() => setOpenAssignCounsellor(true)}
									style={{ width: "100%", height: "3.5rem" }}
									className="btn btn-primary"
								>
									Assign
								</button>
							</Col>

							<Col xl={3}>
								<CardHeader title="Selected Counsellors" />
								<TextField
									id="outlined-textarea"
									placeholder="Swapnil Chatterjee, ..."
									multiline
									sx={{ width: "100%" }}
									value={finalSelectedCounsellors
										.map((counsellor) => counsellor.name)
										.join(", ")}
								/>
							</Col>

							<Col xl={3}>
								<CardHeader title="Purpose" />
								<TextField
									id="outlined-textarea"
									placeholder="Enter Session Purpose..."
									multiline
									value={session.purpose}
									onChange={(e) =>
										setSession({ ...session, purpose: e.target.value })
									}
									sx={{ width: "100%" }}
								/>
							</Col>

							<Col xl={2}>
								<div
									style={{
										display: "flex",
										width: "100%",
										justifyContent: "start",
										alignItems: "center",
										marginTop: "3.25rem",
									}}
								>
									<Button
										className="btn btn-primary"
										onClick={(e) => {
											e.preventDefault();
											addSession();
										}}
									>
										Add
									</Button>
								</div>
							</Col>

							<Col xl={12} />
						</Row>
					</Box>
				</Col>

				{/* Assign Student */}
				<Modal
					aria-labelledby="modal-title"
					aria-describedby="modal-desc"
					open={openAssignCounsellor}
					onClose={() => setOpenAssignCounsellor(false)}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: "1000",
					}}
				>
					<Sheet
						variant="outlined"
						sx={{
							width: "90%",
							height: "40rem",
							overflowY: "scroll",
							borderRadius: "md",
							p: 3,
							boxShadow: "lg",
							bgcolor: "#fff",
						}}
					>
						<ModalClose
							variant="outlined"
							sx={{
								top: "calc(-1/4 * var(--IconButton-size))",
								right: "calc(-1/4 * var(--IconButton-size))",
								boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
								borderRadius: "50%",
								bgcolor: "#eee",
							}}
						/>

						<Row>
							<Col xl={12}>
								<Typography
									id="basic-modal-dialog-title"
									component="h2"
									level="inherit"
									fontSize="1.25em"
									mb={1}
								>
									Assign Counsellors
								</Typography>
							</Col>

							<Col xl={12}>
								<Box className="mc-card">
									<Row xs={1} md={1} xl={1}>
										<Col xl={2}>
											<CardHeader title="Subject" />
											<FormControl sx={{ width: "100%" }}>
												<InputLabel id="demo-simple-select-label">
													Subject Area
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="Subject"
													value={filters.subject}
													onChange={(e) =>
														setFilters({
															...filters,
															subject: e.target.value,
														})
													}
												>
													<MenuItem value={""}>None</MenuItem>
													{subjects.length > 0 &&
														subjects.map((subject) => {
															return (
																<MenuItem value={subject._id}>
																	{subject.name}
																</MenuItem>
															);
														})}
												</Select>
											</FormControl>
										</Col>

										<Col xl={2}>
											<CardHeader title="State" />
											<FormControl sx={{ width: "100%" }}>
												<InputLabel id="demo-simple-select-label">
													State
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="State"
													value={filters.state}
													onChange={(e) => {
														const selectedState = stateList.find(
															(state) => state.name === e.target.value,
														);
														setFilters({ ...filters, state: e.target.value });
														console.log(selectedState?.id); // Get stateId correctly
														getCity(selectedState?.id);
														setCallApi(!callApi);
													}}
												>
													<MenuItem value={""}>None</MenuItem>
													{stateList.length > 0 &&
														stateList.map((state) => {
															return (
																<MenuItem value={state.name}>
																	{state.name}
																</MenuItem>
															);
														})}
												</Select>
											</FormControl>
										</Col>
										<Col xl={2}>
											<CardHeader title="District" />
											<FormControl sx={{ width: "100%" }}>
												<InputLabel id="demo-simple-select-label">
													District
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="District"
													value={filters.district}
													onChange={(e) => {
														setFilters({
															...filters,
															district: e.target.value,
														});
														setCallApi(!callApi);
													}}
												>
													<MenuItem value={""}>None</MenuItem>
													{cityList.length > 0 &&
														cityList.map((city) => {
															return (
																<MenuItem value={city.name}>
																	{city.name}
																</MenuItem>
															);
														})}
												</Select>
											</FormControl>
										</Col>

										<Col xl={2}>
											<CardHeader title="Profession" />
											<FormControl sx={{ width: "100%" }}>
												<InputLabel id="demo-simple-select-label">
													Profession
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="Profession"
													value={filters.profession}
													onChange={(e) =>
														setFilters({
															...filters,
															experience: e.target.value,
														})
													}
												>
													<MenuItem value={""}>None</MenuItem>
													<MenuItem value={"engineer"}>engineer</MenuItem>
												</Select>
											</FormControl>
										</Col>
										<Col xl={2}>
											<CardHeader title="Experience" />
											<FormControl sx={{ width: "100%" }}>
												<InputLabel id="demo-simple-select-label">
													Experience
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="Experience"
													value={filters.experience}
													onChange={(e) =>
														setFilters({
															...filters,
															experience: e.target.value,
														})
													}
												>
													<MenuItem value={""}>None</MenuItem>
													<MenuItem value={"1"}>1</MenuItem>
													<MenuItem value={"2"}>2</MenuItem>
													<MenuItem value={"3"}>3</MenuItem>
													<MenuItem value={"4"}>4</MenuItem>
													<MenuItem value={"5"}>5</MenuItem>
												</Select>
											</FormControl>
										</Col>

										<Col xl={2}>
											<CardHeader title="Qualification" />
											<FormControl sx={{ width: "100%" }}>
												<InputLabel id="demo-simple-select-label">
													Qualification
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="Qualification"
													value={filters.qualification}
													onChange={(e) =>
														setFilters({
															...filters,
															qualification: e.target.value,
														})
													}
												>
													<MenuItem value={""}>None</MenuItem>
													{qualifications.length > 0 &&
														qualifications.map((qualification) => {
															return (
																<MenuItem value={qualification}>
																	{qualification}
																</MenuItem>
															);
														})}
												</Select>
											</FormControl>
										</Col>

										<Col xl={1}>
											<div
												style={{
													display: "flex",
													width: "100%",
													justifyContent: "start",
													alignItems: "center",
													marginTop: "3.25rem",
												}}
											>
												<Button
													className="btn btn-primary"
													onClick={(e) => {
														e.preventDefault();
														fetchCounsellorsList();
													}}
												>
													Apply
												</Button>
											</div>
										</Col>
										<Col xl={12}>
											<MDBDataTable
												striped
												bordered
												small
												data={counsellor_data}
												style={{ marginTop: "1rem" }}
											/>
										</Col>
									</Row>
								</Box>
							</Col>
						</Row>

						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								marginTop: "2rem",
							}}
						>
							<button
								type="button"
								onClick={() => {
									setFinalSelectedCounsellors(selectedCounsellors);
									setOpenAssignCounsellor(false);
								}}
								style={{
									width: "8rem",
									fontSize: "0.8rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginRight: "0.5rem",
								}}
								className="btn btn-primary"
								disabled={
									(counselling_type == "one" &&
										selectedCounsellors.length > 0 &&
										selectedCounsellors.length === 1) ||
									counselling_type == "group"
										? false
										: true
								}
							>
								Assign
							</button>
						</div>
					</Sheet>
				</Modal>

				<Modal
					aria-labelledby="modal-title"
					aria-describedby="modal-desc"
					open={showTimeSlots}
					onClose={() => setShowTimeSlots(false)}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: "1000",
					}}
				>
					<Sheet
						variant="outlined"
						sx={{
							width: "90%",
							height: "40rem",
							overflowY: "scroll",
							borderRadius: "md",
							p: 3,
							boxShadow: "lg",
							bgcolor: "#fff",
						}}
					>
						<ModalClose
							variant="outlined"
							sx={{
								boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
								borderRadius: "50%",
								bgcolor: "#eee",
							}}
						/>

						<Row>
							<Col xl={12}>
								<Typography
									id="basic-modal-dialog-title"
									component="h2"
									level="inherit"
									fontSize="1.25em"
									mb={1}
								>
									Time Slots
								</Typography>
							</Col>

							<Col xl={12}>
								<Box className="mc-card">
									{days.length > 0 &&
										days.map((day, index) => {
											return (
												<Row xs={1} md={1} xl={1} style={{ marginTop: "2rem" }}>
													<Col xl={3}>
														<CardHeader title="Selected Day" />
														<FormControl sx={{ width: "100%" }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={day}
																disabled
															>
																<MenuItem value={"monday"}>Monday</MenuItem>
																<MenuItem value={"tuesday"}>Tuesday</MenuItem>
																<MenuItem value={"wednesday"}>
																	Wednesday
																</MenuItem>
																<MenuItem value={"thursday"}>Thursday</MenuItem>
																<MenuItem value={"friday"}>Friday</MenuItem>
																<MenuItem value={"saturday"}>Saturday</MenuItem>
																<MenuItem value={"sunday"}>Sunday</MenuItem>
															</Select>
														</FormControl>
													</Col>

													<Col xl={3} />

													<Col xl={6}>
														<CardHeader title="Selected Time Slots" />

														<Row>
															{selectedCounsellor.slots.length > 0 &&
																selectedCounsellor.slots.map((slot, index) => {
																	if (slot.day === day) {
																		return (
																			<Col xl={4}>
																				<label className="checkbox-button-container">
																					<input
																						type="checkbox"
																						disabled
																						value={`${slot.start_time} - ${slot.end_time}`}
																					/>
																					<div
																						className="checkbox-button"
																						disabled
																					>{`${slot.start_time} - ${slot.end_time}`}</div>
																				</label>
																			</Col>
																		);
																	}
																})}
														</Row>
													</Col>
												</Row>
											);
										})}
								</Box>
							</Col>
						</Row>
					</Sheet>
				</Modal>
			</Row>
		</PageLayout>
	);
}
