import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { isEqual } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 100,
		},
	},
};

export default function EditCounsellor() {
	const location = useLocation();
	const [selectedDay, setSelectedDay] = useState("");
	const Counsellor = location.state.Counsellor;
	const [professions, setProfessions] = useState([]);
	const [days, setDays] = useState([]);
	const [counsellor, setCounsellor] = useState({
		name: Counsellor && Counsellor.name,
		age: Counsellor && Counsellor.age,
		email: Counsellor && Counsellor.email,
		state: Counsellor && Counsellor.state,
		district: Counsellor && Counsellor.district,
		qualification: Counsellor && Counsellor.qualification,
		subjects: Counsellor.subjects.length > 0 && Counsellor.subjects,
		profession: Counsellor && Counsellor.profession._id,
		experience: Counsellor && Counsellor.experience,
		interested_streams:
			Counsellor.interested_streams.length > 0 && Counsellor.interested_streams,
		slots: Counsellor.slots.length > 0 && Counsellor.slots,
		password: Counsellor && Counsellor.password,
		mobile: Counsellor && Counsellor.mobile,
	});
	const [subject_data, setSubject_Data] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [Qualification, setQualification] = useState([]);
	const [streams, setStreams] = React.useState(
		Array.isArray(Counsellor.interested_streams)
			? Counsellor.interested_streams.map((stream) => stream.name)
			: [],
	);

	const [selectedTimeSlots, setSelectedTimeSlots] = useState(Counsellor.slots);
	const [Streams, setStream] = useState([]);
	const navigate = useNavigate();

	const getStates = async () => {
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}home/stateList`)
			.then((res) => {
				if (res.data.responseCode) {
					setStateList(res.data.responseData);
					const selectedState = res.data.responseData.find(
						(state) => state?.name === counsellor?.state,
					);
					getCity(selectedState?.id);
				} else {
					Error(res.data.message);
				}
			})
			.catch((e) => {
				Error(e.response.data.message);
			});
	};
	const getCity = async (value) => {
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}home/cityList`, {
				stateId: value,
			})
			.then((res) => {
				if (res.data.responseCode) {
					setCityList(res.data.responseData);
				} else {
					Error(res.data.message);
				}
			})
			.catch((e) => {
				Error(e.response.data.message);
			});
	};

	const Error = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const handleSubjectChange = (event) => {
		const {
			target: { value },
		} = event;

		let updatedSubjects = [];

		// On autofill we get a stringified value.
		const selectedValues = typeof value === "string" ? value.split(",") : value;

		if (Array.isArray(selectedValues)) {
			updatedSubjects = selectedValues.map((selectedId) => {
				const selectedSubject = subject_data.find(
					(subject) => subject._id === selectedId,
				);
				return selectedSubject
					? { _id: selectedSubject._id, name: selectedSubject.name }
					: null;
			});
		}

		updatedSubjects = updatedSubjects.filter((subject) => subject !== null);

		setCounsellor({
			...counsellor,
			subjects: updatedSubjects,
		});
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setCounsellor({ ...counsellor, [name]: value });
	};

	const fetchSubjects = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}subject-area/get-all-subject-area`)
			.then((res) => {
				setSubject_Data(res.data.SubjectArea);
			})
			.catch((e) => {});
	};
	const fetchQualification = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_BASE_URL}qualification//get-all-Qualifications`,
			)
			.then((res) => {
				setQualification(res.data.Qualifications);
			})
			.catch((e) => {
				setQualification([]);
			});
	};

	const fetchStreams = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}streams/get-all-streams`)
			.then((res) => {
				setStream(res.data.Streams);
			})
			.catch((e) => {
				setStream([]);
			});
	};

	const fetchProfessions = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
			.then((res) => {
				setProfessions(res.data.Professions);
			})
			.catch((e) => {
				setProfessions([]);
			});
	};

	const handleStreamChange = (event) => {
		const {
			target: { value },
		} = event;
		const selectedIds = typeof value === "string" ? value.split(",") : value;
		const selectedNames = Streams.filter((stream) =>
			selectedIds.includes(stream._id),
		).map((stream) => stream.name);
		setCounsellor({
			...counsellor,
			interested_streams: selectedIds,
		});
		setStreams(selectedNames);
	};

	const streams_data = ["science", "commerce"];

	const handleCheckboxClick = (e, day) => {
		const value = e.target.value;
		const [startTime, endTime] = value.split(" - ");

		const slot = { day, start_time: startTime, end_time: endTime };

		if (!e.target.checked) {
			setSelectedTimeSlots(
				selectedTimeSlots.filter(
					(s) =>
						!(
							s.day === day &&
							s.start_time === startTime &&
							s.end_time === endTime
						),
				),
			);
		} else {
			setSelectedTimeSlots([...selectedTimeSlots, slot]);
		}
	};

	function hasDuplicates(array) {
		return new Set(array).size !== array.length;
	}

	const editCounsellor = async () => {
		const filteredTimeSlots = selectedTimeSlots.filter((slot) => {
			return days.includes(slot.day);
		});

		setSelectedTimeSlots(filteredTimeSlots);

		const updatedCounsellor = {
			...counsellor,
			slots: filteredTimeSlots,
		};
		setCounsellor(updatedCounsellor);

		if (
			counsellor.name &&
			counsellor.age &&
			counsellor.email &&
			counsellor.state &&
			counsellor.district &&
			counsellor.qualification &&
			counsellor.subjects.length > 0 &&
			counsellor.profession &&
			counsellor.experience &&
			counsellor.interested_streams.length > 0 &&
			counsellor.slots.length > 0 &&
			counsellor.mobile
		) {
			if (hasDuplicates(days) === false) {
				await axios
					.patch(
						`${process.env.REACT_APP_BASE_URL}counsellor/update-counsellor/${Counsellor._id}`,
						updatedCounsellor,
					)
					.then((res) => {
						navigate("/counsellors-list");
					})
					.finally(() => {
						// navigate('/counsellors-list')
					})
					.catch((e) => {
						Error(e.response.data.message);
					});
			} else {
				Error("Invalid Slots !");
			}
		} else {
			Error("Please fill all the fields !");
		}
	};

	const calculateDays = () => {
		let SlotDays = [];
		Counsellor.slots.map((slot) => {
			if (!SlotDays.includes(slot.day)) {
				SlotDays.push(slot.day);
			}
		});
		setDays(SlotDays);
	};

	useEffect(() => {
		calculateDays();
		if (!Qualification.length > 0) {
			fetchQualification();
		}
		if (!Streams.length > 0) {
			fetchStreams();
		}
	}, [Qualification, Streams]);

	useEffect(() => {
		getStates();
		fetchSubjects();
		fetchProfessions();
	}, []);
	return (
		<PageLayout>
			<ToastContainer autoClose={1000} />
			<Row>
				<Col xl={12}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Breadcrumb title={"Add Counsellors"}></Breadcrumb>
						<Link to="/counsellors-list">
							<Button className="btn btn-primary">View Counsellors</Button>
						</Link>
					</div>
				</Col>
				<Col xl={12}>
					<Box className="mc-card">
						<Row xs={1} md={1} xl={1}>
							<Col xl={3}>
								<CardHeader title="Counsellor Name" />
								<TextField
									id="outlined-required"
									defaultValue=""
									name="name"
									value={counsellor.name}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Counsellor Name..."
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Age" />
								<TextField
									id="outlined-required"
									defaultValue=""
									name="age"
									value={counsellor.age}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Enter Age..."
								/>
							</Col>

							<Col xl={3}>
								<CardHeader title="Email Address" />
								<TextField
									id="outlined-required"
									defaultValue=""
									type="email"
									name="email"
									value={counsellor.email}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Enter Email..."
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Mobile Number" />
								<TextField
									id="outlined-required"
									defaultValue=""
									type="tel"
									name="mobile"
									value={counsellor.mobile}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Enter Mobile..."
								/>
							</Col>
							<Col xl={2}>
								<CardHeader title="State" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="state"
										value={counsellor.state}
										onChange={(e) => {
											const selectedState = stateList.find(
												(state) => state.name === e.target.value,
											);
											setCounsellor({ ...counsellor, state: e.target.value });
											getCity(selectedState?.id);
										}}
									>
										{stateList.length > 0 &&
											stateList.map((state, index) => {
												return (
													<MenuItem
														selected={
															counsellor.state == state.name ? true : false
														}
														key={index}
														value={state.name}
													>
														{state.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>
							<Col xl={2}>
								<CardHeader title="District" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="district"
										value={counsellor.district}
										onChange={handleChange}
									>
										{cityList.length > 0 &&
											cityList.map((district, index) => {
												return (
													<MenuItem
														selected={
															district.name == counsellor.district
																? true
																: false
														}
														key={index}
														value={district.name}
													>
														{district.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<CardHeader title="Highest Qualification" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="qualification"
										value={counsellor.qualification}
										onChange={handleChange}
									>
										{Qualification &&
											Qualification.map((item, index) => (
												<MenuItem
													selected={
														counsellor.qualification == item?._id?.toString()
															? true
															: false
													}
													value={item.name}
												>
													{item.name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Col>

							<Col xl={3}>
								<CardHeader title="Subject Area" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										value={counsellor.subjects.map((subject) => subject._id)} // Extracting selected IDs
										onChange={handleSubjectChange}
										input={<OutlinedInput />}
										renderValue={(selected) =>
											selected
												.map((subjectId) => {
													const selectedSubject = subject_data.find(
														(subject) => subject._id === subjectId,
													);
													return selectedSubject ? selectedSubject.name : "";
												})
												.join(", ")
										}
										MenuProps={MenuProps}
									>
										{subject_data.map((subject) => (
											<MenuItem key={subject._id} value={subject._id}>
												<Checkbox
													checked={counsellor.subjects.some(
														(selectedSubject) =>
															selectedSubject._id === subject._id,
													)}
												/>
												{/* Updated checked property */}
												<ListItemText primary={subject.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<CardHeader title="Profession" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="profession"
										value={counsellor.profession}
										onChange={handleChange}
									>
										{professions.map((profession) => (
											<MenuItem key={profession._id} value={profession._id}>
												{profession.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Col>
							<Col xl={3}>
								<CardHeader title="Interested Streams" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										value={counsellor.interested_streams} // Use stream IDs to manage selection
										onChange={handleStreamChange}
										input={<OutlinedInput />}
										renderValue={() => streams.join(", ")} // Display the names (not the IDs)
										MenuProps={MenuProps}
									>
										{Streams.map((data) => (
											<MenuItem key={data._id} value={data._id}>
												<Checkbox
													checked={counsellor.interested_streams.some(
														(stream) => stream._id === data._id,
													)}
												/>
												<ListItemText primary={data.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<CardHeader title="Years of Experience" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="experience"
										value={counsellor.experience}
										onChange={handleChange}
									>
										<MenuItem value={"1"}>1 Year</MenuItem>
										<MenuItem value={"2"}>2 Years</MenuItem>
										<MenuItem value={"3"}>3 Years</MenuItem>
									</Select>
								</FormControl>
							</Col>

							<Col xl={12}>
								<Box className="mc-card">
									<Breadcrumb title={"Select Time Slots"}>
										<span
											className="btn btn-primary btn-circle"
											onClick={() => {
												setCounsellor({
													...counsellor,
													slots: [...counsellor.slots, ...selectedTimeSlots],
												});
												setDays([...days, selectedDay]);
												setSelectedDay("");
											}}
										>
											<i className="fas fa-plus"></i>
										</span>
									</Breadcrumb>

									{days.length > 0 &&
										days.map((day, index) => {
											return (
												<Row xs={1} md={1} xl={1} style={{ marginTop: "2rem" }}>
													<Col xl={3}>
														<CardHeader title="Selected Day" />
														<FormControl sx={{ width: "100%" }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={day}
																onChange={(e) => {
																	if (days.includes(day)) {
																		setDays(
																			days.map((d) =>
																				d === day ? e.target.value : d,
																			),
																		);
																	} else {
																		setDays((preValue) => {
																			return [...preValue, day];
																		});
																	}
																}}
															>
																<MenuItem
																	value={"monday"}
																	disabled={days.includes("monday")}
																>
																	Monday
																</MenuItem>
																<MenuItem
																	value={"tuesday"}
																	disabled={days.includes("tuesday")}
																>
																	Tuesday
																</MenuItem>
																<MenuItem
																	value={"wednesday"}
																	disabled={days.includes("wednesday")}
																>
																	Wednesday
																</MenuItem>
																<MenuItem
																	value={"thursday"}
																	disabled={days.includes("thursday")}
																>
																	Thursday
																</MenuItem>
																<MenuItem
																	value={"friday"}
																	disabled={days.includes("friday")}
																>
																	Friday
																</MenuItem>
																<MenuItem
																	value={"saturday"}
																	disabled={days.includes("saturday")}
																>
																	Saturday
																</MenuItem>
																<MenuItem
																	value={"sunday"}
																	disabled={days.includes("sunday")}
																>
																	Sunday
																</MenuItem>
															</Select>
														</FormControl>
													</Col>

													<Col xl={3} />

													<Col xl={6}>
														<CardHeader title="Selected Time Slots" />

														{selectedTimeSlots.length > 0 && (
															<>
																<Row>
																	<Col xl={4}>
																		<label className="checkbox-button-container">
																			<input
																				type="checkbox"
																				checked={selectedTimeSlots.some(
																					(slot) =>
																						slot.day === day &&
																						slot.start_time === "08AM" &&
																						slot.end_time === "10AM", // completely overlaps
																				)}
																				value="08AM - 10AM"
																				onClick={(e) =>
																					handleCheckboxClick(e, day)
																				}
																			/>
																			<div className="checkbox-button">
																				08AM - 10AM
																			</div>
																		</label>
																	</Col>
																	<Col xl={4}>
																		<label className="checkbox-button-container">
																			<input
																				type="checkbox"
																				checked={selectedTimeSlots.some(
																					(slot) =>
																						slot.day === day &&
																						slot.start_time === "10AM" &&
																						slot.end_time === "12PM", // completely overlaps
																				)}
																				value="10AM - 12PM"
																				onClick={(e) =>
																					handleCheckboxClick(e, day)
																				}
																			/>
																			<div className="checkbox-button">
																				10AM - 12PM
																			</div>
																		</label>
																	</Col>
																	<Col xl={4}>
																		<label className="checkbox-button-container">
																			<input
																				type="checkbox"
																				checked={selectedTimeSlots.some(
																					(slot) =>
																						slot.day === day &&
																						slot.start_time === "12PM" &&
																						slot.end_time === "02PM", // completely overlaps
																				)}
																				value="12PM - 02PM"
																				onClick={(e) =>
																					handleCheckboxClick(e, day)
																				}
																			/>
																			<div className="checkbox-button">
																				12PM - 02PM
																			</div>
																		</label>
																	</Col>
																</Row>

																<Row style={{ marginTop: "1rem" }}>
																	<Col xl={4}>
																		<label className="checkbox-button-container">
																			<input
																				type="checkbox"
																				checked={selectedTimeSlots.some(
																					(slot) =>
																						slot.day === day &&
																						slot.start_time === "02PM" &&
																						slot.end_time === "04PM", // completely overlaps
																				)}
																				value="02PM - 04PM"
																				onClick={(e) =>
																					handleCheckboxClick(e, day)
																				}
																			/>
																			<div className="checkbox-button">
																				02PM - 04PM
																			</div>
																		</label>
																	</Col>
																	<Col xl={4}>
																		<label className="checkbox-button-container">
																			<input
																				type="checkbox"
																				checked={selectedTimeSlots.some(
																					(slot) =>
																						slot.day === day &&
																						slot.start_time === "04PM" &&
																						slot.end_time === "06PM", // completely overlaps
																				)}
																				value="04PM - 06PM"
																				onClick={(e) =>
																					handleCheckboxClick(e, day)
																				}
																			/>
																			<div className="checkbox-button">
																				04PM - 06PM
																			</div>
																		</label>
																	</Col>
																	<Col xl={4}>
																		<label className="checkbox-button-container">
																			<input
																				type="checkbox"
																				checked={selectedTimeSlots.some(
																					(slot) =>
																						slot.day === day &&
																						slot.start_time === "06PM" &&
																						slot.end_time === "08PM", // completely overlaps
																				)}
																				value="06PM - 08PM"
																				onClick={(e) =>
																					handleCheckboxClick(e, day)
																				}
																			/>
																			<div className="checkbox-button">
																				06PM - 08PM
																			</div>
																		</label>
																	</Col>
																</Row>
															</>
														)}
													</Col>
												</Row>
											);
										})}

									<Row xs={1} md={1} xl={1} style={{ marginTop: "2rem" }}>
										<Col xl={3}>
											<CardHeader title="Select Day" />
											<FormControl sx={{ width: "100%" }}>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={selectedDay}
													onChange={(e) => setSelectedDay(e.target.value)}
												>
													{!days.includes("monday") && (
														<MenuItem value={"monday"}>Monday</MenuItem>
													)}
													{!days.includes("tuesday") && (
														<MenuItem value={"tuesday"}>Tuesday</MenuItem>
													)}
													{!days.includes("wednesday") && (
														<MenuItem value={"wednesday"}>Wednesday</MenuItem>
													)}
													{!days.includes("thursday") && (
														<MenuItem value={"thursday"}>Thursday</MenuItem>
													)}
													{!days.includes("friday") && (
														<MenuItem value={"friday"}>Friday</MenuItem>
													)}
													{!days.includes("saturday") && (
														<MenuItem value={"saturday"}>Saturday</MenuItem>
													)}
													{!days.includes("sunday") && (
														<MenuItem value={"sunday"}>Sunday</MenuItem>
													)}
												</Select>
											</FormControl>
										</Col>

										<Col xl={3} />

										<Col xl={6}>
											<CardHeader title="Select Time Slots" />

											<Row>
												<Col xl={4}>
													<label className="checkbox-button-container">
														<input
															type="checkbox"
															checked={selectedTimeSlots.some(
																(slot) =>
																	slot.day === selectedDay &&
																	slot.start_time === "08AM" &&
																	slot.end_time === "10AM", // completely overlaps
															)}
															value="08AM - 10AM"
															onClick={(e) =>
																handleCheckboxClick(e, selectedDay)
															}
														/>
														<div className="checkbox-button">08AM - 10AM</div>
													</label>
												</Col>
												<Col xl={4}>
													<label className="checkbox-button-container">
														<input
															type="checkbox"
															checked={selectedTimeSlots.some(
																(slot) =>
																	slot.day === selectedDay &&
																	slot.start_time === "10AM" &&
																	slot.end_time === "12PM", // completely overlaps
															)}
															value="10AM - 12PM"
															onClick={(e) =>
																handleCheckboxClick(e, selectedDay)
															}
														/>
														<div className="checkbox-button">10AM - 12PM</div>
													</label>
												</Col>
												<Col xl={4}>
													<label className="checkbox-button-container">
														<input
															type="checkbox"
															checked={selectedTimeSlots.some((slot) =>
																isEqual(slot, {
																	day: selectedDay,
																	start_time: "12PM",
																	end_time: "02PM",
																}),
															)}
															value="12PM - 02PM"
															onClick={(e) =>
																handleCheckboxClick(e, selectedDay)
															}
														/>
														<div className="checkbox-button">12PM - 02PM</div>
													</label>
												</Col>
											</Row>

											<Row style={{ marginTop: "1rem" }}>
												<Col xl={4}>
													<label className="checkbox-button-container">
														<input
															type="checkbox"
															checked={selectedTimeSlots.some((slot) =>
																isEqual(slot, {
																	day: selectedDay,
																	start_time: "02PM",
																	end_time: "04PM",
																}),
															)}
															value="02PM - 04PM"
															onClick={(e) =>
																handleCheckboxClick(e, selectedDay)
															}
														/>
														<div className="checkbox-button">02PM - 04PM</div>
													</label>
												</Col>
												<Col xl={4}>
													<label className="checkbox-button-container">
														<input
															type="checkbox"
															checked={selectedTimeSlots.some((slot) =>
																isEqual(slot, {
																	day: selectedDay,
																	start_time: "04PM",
																	end_time: "06PM",
																}),
															)}
															value="04PM - 06PM"
															onClick={(e) =>
																handleCheckboxClick(e, selectedDay)
															}
														/>
														<div className="checkbox-button">04PM - 06PM</div>
													</label>
												</Col>
												<Col xl={4}>
													<label className="checkbox-button-container">
														<input
															type="checkbox"
															checked={selectedTimeSlots.some((slot) =>
																isEqual(slot, {
																	day: selectedDay,
																	start_time: "06PM",
																	end_time: "08PM",
																}),
															)}
															value="06PM - 08PM"
															onClick={(e) =>
																handleCheckboxClick(e, selectedDay)
															}
														/>
														<div className="checkbox-button">06PM - 08PM</div>
													</label>
												</Col>
											</Row>
										</Col>

										<Col xl={12}>
											<div
												style={{
													display: "flex",
													width: "100%",
													justifyContent: "center",
													alignItems: "center",
													marginTop: "3.25rem",
												}}
											>
												<Button
													onClick={editCounsellor}
													className="btn btn-primary"
												>
													Edit Counsellor
												</Button>
											</div>
										</Col>
									</Row>
								</Box>
							</Col>
						</Row>
					</Box>
				</Col>
			</Row>
		</PageLayout>
	);
}
