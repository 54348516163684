import React, { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "../ckeditor/build/ckeditor";

const CKEditorComponent = ({ data, onChange }) => {
  const editorToolbarRef = useRef(null);
  return (
    <div>
      <div ref={editorToolbarRef}></div>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        onReady={(editor) => {
          if (editorToolbarRef.current) {
            editorToolbarRef.current.appendChild(
              editor.ui.view.toolbar.element,
              (editor.ui.view.editable.element.style.border = "1"),
              (editor.ui.view.editable.element.style.borderColor = "black")
            );
          }
        }}
        onAfterDestroy={() => {
          if (editorToolbarRef.current) {
            Array.from(editorToolbarRef.current.children).forEach((child) =>
              child.remove()
            );
          }
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default CKEditorComponent;
