import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 100,
		},
	},
};

export default function AddBatch() {
	const [grades, setGrades] = useState([]);
	const currentDate = dayjs();
	const [aspirations, setAspirations] = useState([]);
	const [endValue, setEndValue] = React.useState("");
	const [StartValue, setStartValue] = React.useState("");
	const [aspiration, setAspiration] = useState("");
	const [grade, setGrade] = useState("");
	const navigate = useNavigate();
	const [callApi, setCallApi] = useState(false);
	const [students, setStudents] = useState([]);
	const [openAssignStudents, setOpenAssignStudents] = useState(false);
	const [selectedStudents, setSelectedStudents] = useState([]);
	const [boards, setBoards] = useState([]);
	const [board, setBoard] = useState("");
	const [selectAll, setSelectAll] = useState(false);
	const handleSelectAll = (event) => {
		const isChecked = event.target.checked;
		setSelectAll(isChecked);
		if (isChecked) {
			setSelectedStudents((prevSelectedQuestions) => {
				const newSelectedStu = [...prevSelectedQuestions];
				for (const student of students) {
					const stuExists = newSelectedStu.some(
						(selectedQuestion) => selectedQuestion === student?.userId?._id,
					);
					if (!stuExists) {
						newSelectedStu.push(student?.userId?._id);
					}
				}
				return newSelectedStu;
			});
		} else {
			setSelectedStudents([]);
		}
	};
	const handleSetStartValue = (newValue) => {
		const isoFormattedTime = newValue.toISOString();
		setStartValue(isoFormattedTime);
	};
	const handleSetEndValue = (newValue) => {
		const isoFormattedTime = newValue.toISOString();
		setEndValue(isoFormattedTime);
	};

	const handleCheckboxChange = (event, student) => {
		const isChecked = event.target.checked;

		if (isChecked) {
			// Add the question to the selectedQuestions array
			setSelectedStudents((prevSelectedStudent) => [
				...prevSelectedStudent,
				student?.student?._id,
			]);
		} else {
			// Remove the question from the selectedStudents array
			setSelectedStudents((prevSelectedStudents) =>
				prevSelectedStudents.filter(
					(selectedStudent) => selectedStudent !== student?.student?._id,
				),
			);
		}
	};

	const [batch, setBatch] = useState({
		number: "",
		aspirant: "",
		year: "",
		quarter: "",
		boards: [],
		students: [],
	});
	const fetchBoards = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
			.then((res) => {
				setBoards(res.data.Boards);
			})
			.finally(() => {})
			.catch((e) => {
				setBoards([]);
			});
	};
	const getAllCounsellings = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_URL}package/get-group-counselling-students`,
				{
					aspiration,
					grade,
					endValue,
					StartValue,
					board,
					batchId: true,
				},
			)
			.then((res) => {
				setStudents(res.data.studentDetails);
			})
			.catch((e) => {});
	};

	const handleBoardChange = (value) => {
		if (value.includes("all")) {
			// Selecting all boards
			setBatch({ ...batch, boards: boards.map((board) => board._id) });
		} else {
			// Selecting a specific board
			if (value.length > 0) {
				setBatch({ ...batch, boards: [value[value.length - 1]] });
			} else {
				setBatch({ ...batch, boards: [] });
			}
		}
	};
	const student_data = {
		columns: [
			{
				label: (
					<>
						S.no&nbsp;{" "}
						<input
							type="checkbox"
							checked={selectAll}
							onChange={handleSelectAll}
						/>
					</>
				),
				field: "s_no",
				sort: "asc",
				width: 50,
			},
			{
				label: "First Name",
				field: "first_name",
				sort: "asc",
				width: 150,
			},
			{
				label: "Last Name",
				field: "last_name",
				sort: "asc",
				width: 150,
			},
			{
				label: "Phone",
				field: "phone",
				sort: "asc",
				width: 200,
			},
			{
				label: "Board",
				field: "board",
				sort: "asc",
				width: 100,
			},
			{
				label: "School",
				field: "school",
				sort: "asc",
				width: 150,
			},
			{
				label: "Aspirant",
				field: "aspirant",
				sort: "asc",
				width: 100,
			},
			{
				label: "State",
				field: "state",
				sort: "asc",
				width: 100,
			},
			{
				label: "District",
				field: "district",
				sort: "asc",
				width: 100,
			},
			{
				label: "Grade",
				field: "grade",
				sort: "asc",
				width: 100,
			},
			{
				label: "DOJ",
				field: "doj",
				sort: "asc",
				width: 100,
			},
			{
				label: "DOE",
				field: "doe",
				sort: "asc",
				width: 100,
			},
		],
		rows: students?.map((student, index) => {
			return {
				s_no: (
					<th scope="row">
						<input
							type="checkbox"
							checked={selectedStudents.some(
								(selectedStudent) => selectedStudent === student?.student?._id,
							)}
							onChange={(event) => handleCheckboxChange(event, student)}
						/>
						&nbsp;{index + 1}
					</th>
				),
				first_name: student?.student?.first_name,
				last_name: student?.student?.last_name,
				board: student?.student?.board,
				school: student?.student?.school,
				phone: student?.student?.mobile,
				aspirant: student?.student?.aspirant,
				state: student?.student?.state,
				district: student?.student?.district,
				grade: student?.student?.grade,
				doj: moment(student?.student?.doj).format("DD-MM-YYYY"),
				doe: moment(student?.student?.doe).format("DD-MM-YYYY"),
			};
		}),
	};

	const fetchGrades = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}grade/get-all-grades`)
			.then((res) => {
				setGrades(res.data.Grades);
			})
			.catch((e) => {
				setGrades([]);
			});
	};

	const addBatch = async () => {
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}batch/add-batch`, {
				aspirant: batch.aspirant,
				year: batch.year,
				number: batch.number,
				quarter: batch.quarter,
				boards: batch.boards,
				students: selectedStudents,
			})
			.then((res) => {
				navigate("/batches-list");
			})
			.catch((e) => {
				Error(e.response.data.message);
			});
	};

	const fetchProfessions = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
			.then((res) => {
				setAspirations(res.data.Professions);
			})
			.catch((e) => {
				setAspirations([]);
			});
	};

	useEffect(() => {
		fetchGrades();
		fetchProfessions();
	}, []);

	useEffect(() => {
		fetchBoards();
		getAllCounsellings();
	}, []);

	return (
		<PageLayout>
			<Row>
				<Col xl={12}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Breadcrumb title={"Add Batch"}></Breadcrumb>
						<Link to="/batches-list">
							<Button className="btn btn-primary">View Batches</Button>
						</Link>
					</div>
				</Col>
				<Col xl={12}>
					<Box className="mc-card">
						<Row xs={1} md={1} xl={1}>
							<Col xl={2}>
								<CardHeader title="Aspirant" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="qualification"
										value={batch.aspirant}
										onChange={(e) =>
											setBatch({ ...batch, aspirant: e.target.value })
										}
									>
										{aspirations.length > 0 &&
											aspirations.map((aspiration) => {
												return (
													<MenuItem value={aspiration.name}>
														{aspiration.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>

							{/* <Col xl={2}>
                <CardHeader title="Aspirant" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  value={batch.aspirant}
                  onChange={(e) =>
                    setBatch({ ...batch, aspirant: e.target.value })
                  }
                />
              </Col> */}

							<Col xl={2}>
								<CardHeader title="Year" />
								<FormControl sx={{ width: 150 }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={batch.year}
										onChange={(e) =>
											setBatch({ ...batch, year: e.target.value })
										}
									>
										<MenuItem value={"2021"}>2021</MenuItem>
										<MenuItem value={"2022"}>2022</MenuItem>
										<MenuItem value={"2023"}>2023</MenuItem>
										<MenuItem value={"2024"}>2024</MenuItem>
										<MenuItem value={"2025"}>2025</MenuItem>
									</Select>
								</FormControl>
							</Col>

							<Col xl={3}>
								<CardHeader title="Quarter" />
								<FormControl sx={{ width: 260 }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={batch.quarter}
										onChange={(e) => {
											setBatch({ ...batch, quarter: e.target.value });
										}}
									>
										<MenuItem value="Q1">Q1(Jan, Feb, Mar)</MenuItem>
										<MenuItem value="Q2">Q2(Apr,May, Jun,)</MenuItem>
										<MenuItem value="Q3">Q3(Jul, Aug,Sep)</MenuItem>
										<MenuItem value="Q4">Q4(Oct, Nov, Dec)</MenuItem>
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<CardHeader title="Batch No." />
								<TextField
									id="outlined-required"
									defaultValue=""
									value={batch.number}
									sx={{ width: "100%" }}
									onChange={(e) =>
										setBatch({ ...batch, number: e.target.value })
									}
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Board" />
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="demo-simple-select-label">Board</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="Board"
										value={batch.boards}
										onChange={(e) => handleBoardChange(e.target.value)}
										multiple
									>
										<MenuItem value="all">ALL</MenuItem>{" "}
										{/* Add default option for selecting all boards */}
										{boards.length > 0 &&
											boards.map((board) => {
												return (
													<MenuItem key={board._id} value={board._id}>
														{board.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<div
									style={{
										marginTop: "2.5rem",
										justifyContent: "start",
										display: "flex",
										width: "100%",
									}}
								>
									<Button
										onClick={() => setOpenAssignStudents(true)}
										className="btn btn-primary"
									>
										Assign Students
									</Button>
								</div>
							</Col>

							<Col xl={2}>
								<CardHeader title="Students Assigned" />
								<TextField
									id="outlined-required"
									defaultValue="0"
									placeholder="0"
									disabled
									value={selectedStudents.length}
									sx={{ width: "100%" }}
								/>
							</Col>

							<Col xl={2}>
								<div
									style={{
										marginTop: "3.25rem",
										justifyContent: "start",
										display: "flex",
										width: "100%",
									}}
								>
									<Button
										className="btn btn-primary"
										onClick={(e) => {
											e.preventDefault();
											addBatch();
										}}
									>
										Add
									</Button>
								</div>
							</Col>

							<Col xl={12} />
						</Row>
					</Box>
				</Col>
			</Row>

			{/* Assign Student */}
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-desc"
				open={openAssignStudents}
				onClose={() => setOpenAssignStudents(false)}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					zIndex: "1000",
				}}
			>
				<Sheet
					variant="outlined"
					sx={{
						width: "90%",
						height: "40rem",
						overflowY: "scroll",
						borderRadius: "md",
						p: 3,
						boxShadow: "lg",
						bgcolor: "#fff",
					}}
				>
					<ModalClose
						variant="outlined"
						sx={{
							boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
							borderRadius: "50%",
							bgcolor: "#eee",
						}}
					/>
					<Typography
						id="basic-modal-dialog-title"
						component="h2"
						level="inherit"
						fontSize="1.25em"
						mb={1}
					>
						Assign Students
					</Typography>

					<Row xs={1} md={1} xl={1} style={{ marginBottom: "2rem" }}>
						<Col xl={12}>
							<Box className="mc-card">
								<Row xs={1} md={1} xl={1}>
									<Col xl={3}>
										<CardHeader title="Start Date" />
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												value={StartValue}
												onChange={handleSetStartValue}
												renderInput={(params) => (
													<TextField {...params} disabled={true} />
												)}
												name="start_time"
												minDateTime={currentDate}
											/>
										</LocalizationProvider>
									</Col>
									<Col xl={3}>
										<CardHeader title="End Date" />
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												value={endValue}
												onChange={handleSetEndValue}
												renderInput={(params) => (
													<TextField {...params} disabled={true} />
												)}
												name="end_time"
												minDateTime={currentDate}
											/>
										</LocalizationProvider>
									</Col>

									<Col xl={2}>
										<div>
											<CardHeader title="Select Aspiration" />
											<FormControl sx={{ width: "100%" }}>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={aspiration}
													onChange={(e) => setAspiration(e.target.value)}
												>
													<MenuItem value="">None</MenuItem>
													{aspirations.length > 0 &&
														aspirations.map((aspiration) => {
															return (
																<MenuItem value={aspiration.name}>
																	{aspiration.name}
																</MenuItem>
															);
														})}
												</Select>
											</FormControl>
										</div>
									</Col>
									<Col xl={2}>
										<CardHeader title="Select Board" />
										<FormControl sx={{ width: 150 }}>
											<InputLabel id="demo-simple-select-label">
												Board
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Board"
												value={board}
												onChange={(e) => {
													setBoard(e.target.value);
													setCallApi(!callApi);
												}}
											>
												<MenuItem value={""}>None</MenuItem>
												{boards.length > 0 &&
													boards.map((board) => {
														return (
															<MenuItem value={board._id}>
																{board.name}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>
									</Col>

									<Col xl={2}>
										<CardHeader title="Select Grade" />
										<FormControl sx={{ width: 150 }}>
											<InputLabel id="demo-simple-select-label">
												Grade
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												label="Grade"
												id="demo-simple-select"
												value={grade}
												onChange={(e) => {
													setGrade(e.target.value);
													setCallApi(!callApi);
												}}
											>
												<MenuItem value={""}>None</MenuItem>
												{grades.length > 0 &&
													grades.map((grade) => {
														return (
															<MenuItem value={grade._id}>
																{grade.name}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>
									</Col>

									<Col xl={10}></Col>
									<Col xl={2}>
										<div
											style={{
												display: "flex",
												width: "100%",
												justifyContent: "end",
												alignItems: "center",
											}}
										>
											<Button
												onClick={(e) => {
													e.preventDefault();
													getAllCounsellings();
												}}
												className="btn btn-primary"
											>
												Apply
											</Button>
										</div>
									</Col>

									<Col xl={12}>
										<Box className="mc-card">
											<div className="container" style={{ marginTop: "1rem" }}>
												<div
													className="row"
													style={{ justifyContent: "end", width: "100%" }}
												></div>
												<div className="row">
													<div className="col-12">
														<MDBDataTable
															striped
															bordered
															small
															data={student_data}
															style={{
																marginTop: "1rem",
																display: "block",
																overflowX: "auto",
																whiteSpace: "nowrap",
															}}
														/>
													</div>
												</div>
											</div>
										</Box>
									</Col>
								</Row>
							</Box>
						</Col>
					</Row>

					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							marginTop: "2rem",
						}}
					>
						<button
							type="button"
							onClick={() => setOpenAssignStudents(false)}
							style={{
								width: "8rem",
								fontSize: "0.8rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginRight: "0.5rem",
							}}
							className="btn btn-primary"
						>
							Assign
						</button>
					</div>
				</Sheet>
			</Modal>
		</PageLayout>
	);
}
