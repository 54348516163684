import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import { isEqual } from "lodash";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 100,
		},
	},
};

export default function AddCounsellor() {
	const [showPassword, setShowPassword] = useState(false);

	const handleTogglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const [days, setDays] = useState([]);
	const [errorCm, setError] = useState(false);
	const [showAdd, setShowAdd] = useState(true);
	const [AddNew, setAddNew] = useState(false);
	const [subject_data, setSubject_Data] = useState([]);

	const [professions, setProfessions] = useState([]);
	const [Qualification, setQualification] = useState([]);
	const [Stream, setStream] = useState([]);
	const [selectedSubjectNames, setSelectedSubjectNames] = useState([]); // To store the names for display

	const navigate = useNavigate();

	const handleSubjectChange = (event) => {
		const {
			target: { value },
		} = event;

		// Ensure value is always an array (handles autofill)
		const selectedValues = typeof value === "string" ? value.split(",") : value;

		// Get selected subject IDs
		const selectedIds = selectedValues;

		// Map selected subject IDs to their corresponding names for display
		const selectedNames = selectedIds
			.map((selectedId) => {
				const selectedSubject = subject_data.find(
					(subject) => subject._id === selectedId,
				);
				return selectedSubject ? selectedSubject.name : null;
			})
			.filter((name) => name !== null); // Filter out any null names

		// Update state to reflect selected subject IDs in counsellor
		setCounsellor({
			...counsellor,
			subjects: selectedIds, // Only store the IDs
		});

		// Update state with subject names for display in the dropdown
		setSelectedSubjectNames(selectedNames);
	};

	const Success = (message) => {
		toast.success(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const Error = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [counsellor, setCounsellor] = useState({
		name: "",
		age: "",
		email: "",
		state: "",
		district: "",
		qualification: "",
		subjects: [],
		profession: "",
		experience: "",
		interested_streams: [],
		slots: [],
		password: "",
		mobile: "",
	});

	const [selectedDay, setSelectedDay] = useState("");
	const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		if (name == "state") {
			getCity(value);
		}
		setCounsellor({ ...counsellor, [name]: value });
	};

	const fetchSubjects = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}subject-area/get-all-subject-area`)
			.then((res) => {
				setSubject_Data(res.data.SubjectArea);
			})
			.catch((e) => {});
	};

	const fetchProfessions = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
			.then((res) => {
				setProfessions(res.data.Professions);
			})
			.catch((e) => {
				setProfessions([]);
			});
	};

	const fetchQualification = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_BASE_URL}qualification//get-all-Qualifications`,
			)
			.then((res) => {
				setQualification(res.data.Qualifications);
			})
			.catch((e) => {
				setQualification([]);
			});
	};

	const [streams, setStreams] = React.useState([]);

	const handleStreamChange = (event) => {
		const {
			target: { value },
		} = event;
		const selectedIds = typeof value === "string" ? value.split(",") : value;
		const selectedNames = Stream.filter((stream) =>
			selectedIds.includes(stream._id),
		).map((stream) => stream.name);
		setCounsellor({
			...counsellor,
			interested_streams: selectedIds,
		});
		setStreams(selectedNames);
	};

	const fetchStreams = async () => {
		await axios
			.get(`${process.env.REACT_APP_BASE_URL}streams/get-all-streams`)
			.then((res) => {
				setStream(res.data.Streams);
			})
			.catch((e) => {
				setStream([]);
			});
	};

	const streams_data = ["Science", "Commerce"];

	const handleCheckboxClick = (e) => {
		const value = e.target.value;
		const [startTime, endTime] = value.split(" - ");
		const day = selectedDay;

		const slot = { day, start_time: startTime, end_time: endTime };
		if (selectedTimeSlots.some((s) => isEqual(s, slot))) {
			setSelectedTimeSlots(selectedTimeSlots.filter((s) => !isEqual(s, slot)));
		} else {
			setSelectedTimeSlots([...selectedTimeSlots, slot]);
		}
	};

	const addCounsellor = async () => {
		if (counsellor.password.length < 8) {
			return Error("Password must be of minimum 8 characters !");
		}
		// setCounsellor({ ...counsellor, interested_streams: streams });

		if (!counsellor.slots.length > 0) {
			setError(true);
		} else if (
			counsellor.name &&
			counsellor.age &&
			counsellor.email &&
			counsellor.state &&
			counsellor.district &&
			counsellor.qualification &&
			counsellor.subjects.length > 0 &&
			counsellor.profession &&
			counsellor.experience &&
			counsellor.interested_streams.length > 0 &&
			counsellor.slots.length > 0 &&
			counsellor.password.length >= 8 &&
			counsellor.mobile &&
			counsellor.slots.length > 0
		) {
			await axios
				.post(
					`${process.env.REACT_APP_BASE_URL}counsellor/register`,
					counsellor,
				)
				.then((res) => {
					console.log(res, counsellor);
					if (res.data.success) {
						Success("Counsellor Added Successfully !");
						navigate("/counsellors-list");
					} else {
						Error(res.data.message);
					}
				})
				.catch((e) => {
					Error(e.response.data.message);
				});
		} else {
			Error("Please fill all the fields !");
		}
	};

	const getStates = async () => {
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}home/stateList`)
			.then((res) => {
				if (res.data.responseCode) {
					setStateList(res.data.responseData);
				} else {
					Error(res.data.message);
				}
			})
			.catch((e) => {
				Error(e.response.data.message);
			});
	};
	const getCity = async (value) => {
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}home/cityList`, {
				stateId: value,
			})
			.then((res) => {
				if (res.data.responseCode) {
					setCityList(res.data.responseData);
				} else {
					Error(res.data.message);
				}
			})
			.catch((e) => {
				Error(e.response.data.message);
			});
	};

	const handleKeyPress = (event) => {
		const keyCode = event.keyCode || event.which;
		const keyValue = String.fromCharCode(keyCode);

		// Only allow numbers and check for maximum length
		const isValidInput =
			/^[0-9]$/.test(keyValue) && event.target.value.length < 10;

		if (!isValidInput) {
			event.preventDefault();
		}
	};

	const ListItems = ({ item, index, counsellor }) => {
		return (
			<Row xs={1} md={1} xl={1} style={{ marginTop: "2rem" }}>
				<Col xl={3}>
					<CardHeader title="Select Day" />
					<FormControl sx={{ width: "100%" }}>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={item}
							disabled
						>
							<MenuItem value={item}>{item}</MenuItem>
						</Select>
					</FormControl>
				</Col>

				<Col xl={2} />
				<Col xl={6}>
					<CardHeader title="Select Time Slots" />
					<Row>
						{counsellor.slots.map((slot, index) => {
							if (slot.day === item) {
								return (
									<Col xl={4}>
										<label className="checkbox-button-container">
											<input
												type="checkbox"
												disabled
												value={`${slot.start_time} - ${slot.end_time}`}
												onClick={handleCheckboxClick}
											/>
											<div
												className="checkbox-button"
												disabled
											>{`${slot.start_time} - ${slot.end_time}`}</div>
										</label>
									</Col>
								);
							}
						})}
					</Row>
				</Col>
				<Col xl={1}>
					<span
						className="btn btn-primary btn-circle"
						onClick={(e) => {
							counsellor.slots.splice(index, 1);
							days.splice(index, 1);
							setCounsellor({
								...counsellor,
								slots: [...counsellor.slots],
							});
							if (!counsellor.slots.length > 0) {
								setAddNew(false);
							}
						}}
					>
						<i className="fas fa-minus"></i>
					</span>
				</Col>
			</Row>
		);
	};

	useEffect(() => {
		getStates();
		fetchQualification();
		fetchSubjects();
		fetchStreams();
		fetchProfessions();
	}, []);

	return (
		<PageLayout>
			<ToastContainer autoClose={1000} />
			<Row>
				<Col xl={12}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Breadcrumb title={"Add Counsellors"}></Breadcrumb>
						<Link to="/counsellors-list">
							<Button className="btn btn-primary">View Counsellors</Button>
						</Link>
					</div>
				</Col>
				<Col xl={12}>
					<Box className="mc-card">
						<Row xs={1} md={1} xl={1}>
							<Col xl={3}>
								<CardHeader title="Counsellor Name" />
								<TextField
									id="outlined-required"
									defaultValue=""
									name="name"
									value={counsellor.name}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Counsellor Name..."
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Age" />
								<TextField
									id="outlined-required"
									defaultValue=""
									name="age"
									value={counsellor.age}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Enter Age..."
								/>
							</Col>

							<Col xl={3}>
								<CardHeader title="Email Address" />
								<TextField
									id="outlined-required"
									defaultValue=""
									type="email"
									name="email"
									value={counsellor.email}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Enter Email..."
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Mobile Number" />
								<TextField
									id="outlined-required"
									defaultValue=""
									type="tel"
									name="mobile"
									value={counsellor.mobile}
									onChange={handleChange}
									onKeyPress={handleKeyPress}
									sx={{ width: "100%" }}
									placeholder="Enter Mobile..."
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="Password" />
								<TextField
									id="outlined-required"
									defaultValue=""
									type={showPassword ? "text" : "password"}
									name="password"
									value={counsellor.password}
									onChange={handleChange}
									sx={{ width: "100%" }}
									placeholder="Enter Password..."
									inputProps={{ minLength: 8 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={handleTogglePasswordVisibility}>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Col>

							<Col xl={2}>
								<CardHeader title="State" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="state"
										value={counsellor.state}
										onChange={handleChange}
									>
										{stateList.length > 0 &&
											stateList.map((state, index) => {
												return (
													<MenuItem key={index} value={state.id}>
														{state.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>
							<Col xl={2}>
								<CardHeader title="District" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="district"
										value={counsellor.district}
										onChange={handleChange}
									>
										{cityList.length > 0 &&
											cityList.map((district, index) => {
												return (
													<MenuItem key={index} value={district.name}>
														{district.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<CardHeader title="Highest Qualification" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="qualification"
										value={counsellor.qualification}
										onChange={handleChange}
									>
										{Qualification.length > 0 &&
											Qualification.map((Qua, index) => {
												return <MenuItem value={Qua._id}>{Qua.name}</MenuItem>;
											})}
									</Select>
								</FormControl>
							</Col>
							<Col xl={3}>
								<CardHeader title="Subject Area" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										value={counsellor.subjects} // Use only subject IDs
										onChange={handleSubjectChange}
										input={<OutlinedInput />}
										renderValue={
											(selected) => selectedSubjectNames.join(", ") // Display subject names
										}
										MenuProps={MenuProps}
									>
										{subject_data.map((subject) => (
											<MenuItem key={subject._id} value={subject._id}>
												<Checkbox
													checked={counsellor.subjects.includes(subject._id)} // Check by subject ID
												/>
												<ListItemText primary={subject.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Col>

							<Col xl={2}>
								<CardHeader title="Profession" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="profession"
										value={counsellor.profession}
										onChange={handleChange}
									>
										{professions.map((profession) => (
											<MenuItem key={profession._id} value={profession._id}>
												{profession.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Col>
							<Col xl={3}>
								<CardHeader title="Interested Streams" />
								<FormControl sx={{ width: "100%" }}>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										value={counsellor.interested_streams} // Use stream IDs to manage selection
										onChange={handleStreamChange}
										input={<OutlinedInput />}
										renderValue={() => streams.join(", ")} // Display the names (not the IDs)
										MenuProps={MenuProps}
									>
										{Stream.map((data) => (
											<MenuItem key={data._id} value={data._id}>
												<Checkbox
													checked={
														counsellor.interested_streams.indexOf(data._id) > -1
													}
												/>
												<ListItemText primary={data.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Col>
							<Col xl={2}>
								<CardHeader title="Years of Experience" />
								<FormControl sx={{ width: "100%" }}>
									<TextField
										type="number"
										id="outlined-required"
										defaultValue=""
										name="experience"
										value={counsellor.experience}
										onChange={handleChange}
										sx={{ width: "100%" }}
										placeholder="Years Of Experience"
									/>
								</FormControl>
							</Col>

							<Col xl={12}>
								<Box className="mc-card">
									<Breadcrumb title={"Select Time Slots"}></Breadcrumb>
									{errorCm && !counsellor.slots.length > 0 ? (
										<Alert severity="error">
											Please select Time Slot and Day
										</Alert>
									) : null}

									{/* show data */}
									{days.length > 0 &&
										days.map((item, index) => {
											return (
												<ListItems
													item={item}
													index={index}
													counsellor={counsellor}
													key={index}
												/>
											);
										})}

									{!counsellor.slots.length > 0 || showAdd === true ? (
										<Row xs={1} md={1} xl={1} style={{ marginTop: "2rem" }}>
											<Col xl={3}>
												<CardHeader title="Select Day" />
												<FormControl sx={{ width: "100%" }}>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														value={selectedDay}
														onChange={(e) => setSelectedDay(e.target.value)}
													>
														{!days.includes("monday") && (
															<MenuItem value={"monday"}>Monday</MenuItem>
														)}
														{!days.includes("tuesday") && (
															<MenuItem value={"tuesday"}>Tuesday</MenuItem>
														)}
														{!days.includes("wednesday") && (
															<MenuItem value={"wednesday"}>Wednesday</MenuItem>
														)}
														{!days.includes("thursday") && (
															<MenuItem value={"thursday"}>Thursday</MenuItem>
														)}
														{!days.includes("friday") && (
															<MenuItem value={"friday"}>Friday</MenuItem>
														)}
														{!days.includes("saturday") && (
															<MenuItem value={"saturday"}>Saturday</MenuItem>
														)}
														{!days.includes("sunday") && (
															<MenuItem value={"sunday"}>Sunday</MenuItem>
														)}
													</Select>
												</FormControl>
											</Col>

											<Col xl={3} />

											<Col xl={6}>
												<CardHeader title="Select Time Slots" />

												<Row>
													<Col xl={4}>
														<label className="checkbox-button-container">
															<input
																type="checkbox"
																checked={selectedTimeSlots.some((slot) =>
																	isEqual(slot, {
																		day: selectedDay,
																		start_time: "08AM",
																		end_time: "10AM",
																	}),
																)}
																value="08AM - 10AM"
																onClick={handleCheckboxClick}
															/>
															<div className="checkbox-button">08AM - 10AM</div>
														</label>
													</Col>
													<Col xl={4}>
														<label className="checkbox-button-container">
															<input
																type="checkbox"
																checked={selectedTimeSlots.some((slot) =>
																	isEqual(slot, {
																		day: selectedDay,
																		start_time: "10AM",
																		end_time: "12PM",
																	}),
																)}
																value="10AM - 12PM"
																onClick={handleCheckboxClick}
															/>
															<div className="checkbox-button">10AM - 12PM</div>
														</label>
													</Col>
													<Col xl={4}>
														<label className="checkbox-button-container">
															<input
																type="checkbox"
																checked={selectedTimeSlots.some((slot) =>
																	isEqual(slot, {
																		day: selectedDay,
																		start_time: "12PM",
																		end_time: "02PM",
																	}),
																)}
																value="12PM - 02PM"
																onClick={handleCheckboxClick}
															/>
															<div className="checkbox-button">12PM - 02PM</div>
														</label>
													</Col>
												</Row>

												<Row style={{ marginTop: "1rem" }}>
													<Col xl={4}>
														<label className="checkbox-button-container">
															<input
																type="checkbox"
																checked={selectedTimeSlots.some((slot) =>
																	isEqual(slot, {
																		day: selectedDay,
																		start_time: "02PM",
																		end_time: "04PM",
																	}),
																)}
																value="02PM - 04PM"
																onClick={handleCheckboxClick}
															/>
															<div className="checkbox-button">02PM - 04PM</div>
														</label>
													</Col>
													<Col xl={4}>
														<label className="checkbox-button-container">
															<input
																type="checkbox"
																checked={selectedTimeSlots.some((slot) =>
																	isEqual(slot, {
																		day: selectedDay,
																		start_time: "04PM",
																		end_time: "06PM",
																	}),
																)}
																value="04PM - 06PM"
																onClick={handleCheckboxClick}
															/>
															<div className="checkbox-button">04PM - 06PM</div>
														</label>
													</Col>
													<Col xl={4}>
														<label className="checkbox-button-container">
															<input
																type="checkbox"
																checked={selectedTimeSlots.some((slot) =>
																	isEqual(slot, {
																		day: selectedDay,
																		start_time: "06PM",
																		end_time: "08PM",
																	}),
																)}
																value="06PM - 08PM"
																onClick={handleCheckboxClick}
															/>
															<div className="checkbox-button">06PM - 08PM</div>
														</label>
													</Col>
												</Row>
											</Col>
										</Row>
									) : null}

									{AddNew ? (
										<Breadcrumb title={""}>
											<span
												className="btn btn-primary btn-circle mt-2"
												onClick={() => {
													setAddNew(false);
													setShowAdd(true);
												}}
											>
												<i className="fas fa-plus"></i> Add New
											</span>
										</Breadcrumb>
									) : null}

									{!counsellor.slots.length > 0 || showAdd ? (
										<Breadcrumb title={""}>
											<span
												className="btn btn-primary btn-circle mt-4"
												onClick={() => {
													setCounsellor({
														...counsellor,
														slots: [...counsellor.slots, ...selectedTimeSlots],
													});
													setDays([...days, selectedDay]);
													setSelectedDay("");
													setSelectedTimeSlots([]);
													setAddNew(true);
													setShowAdd(false);
												}}
											>
												Save
											</span>
										</Breadcrumb>
									) : null}

									<Col xl={12}>
										<div
											style={{
												display: "flex",
												width: "100%",
												justifyContent: "center",
												alignItems: "center",
												marginTop: "3.25rem",
											}}
										>
											<Button
												onClick={addCounsellor}
												className="btn btn-primary"
											>
												Add Counsellor
											</Button>
										</div>
									</Col>
								</Box>
							</Col>
						</Row>
					</Box>
				</Col>
			</Row>
		</PageLayout>
	);
}
